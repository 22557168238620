<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-card-title>
        <h1 class="mx-5 pt-2">
          New Inventory - <small>{{ new Date().toLocaleDateString() }}</small>
        </h1>
      </b-card-title>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- <b-col
            cols="10"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries of {{ inventarioProductos.length }}</label>
          </b-col> -->

          <!-- <b-col
            cols="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              @click="exportToExcel"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </b-col> -->

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="newInventoryTitulo"
                placeholder="Title"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Notes"
              label-for="notes"
            >
              <b-form-input
                id="notes"
                v-model="newInventoryNotas"
                placeholder="Notes"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </div>

      <b-row
        v-for="(item, i) in newInventoryProducts"
        :key="i"
        class="mt-2 ml-2"
      >
        <b-col
          cols="12"
          md="1"
        >
          <b-form-group
            label="stock"
            label-for="number"
          >
            <cleave
              id="number"
              v-model="item.unitInStock"
              class="form-control"
              :raw="false"
              :options="options.number"
              placeholder="10"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <!-- parseInt(newInventoryProducts[i].unitInStock, 10) -->
          <span class="success">
            <feather-icon
              v-if="parseInt(stocksProductos[i].unitInStock, 10) !== parseInt(item.unitInStock, 10)"
              icon="CheckCircleIcon"
              size="16"
              class="mr-1"
            />
            <feather-icon
              v-else
              icon="CircleIcon"
              size="16"
              class="mr-1"
            />
          </span>
          <b-avatar
            size="64"
            :src="item.picture"
            class="mr-3"
          />
          {{ item.id }} - {{ item.name }}
        </b-col>
        <!-- <b-col
          cols="12"
          md="5"
        >
          {{ item.name }}
        </b-col> -->
      </b-row>

      <b-row>
        <b-col
          cols="3"
          class="ml-3 my-3"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon"
            @click="updatedStock"
          >
            <feather-icon icon="DownloadIcon" />
            Save Inventory
          </b-button>
        </b-col>
      </b-row>

      <!-- <pre>{{ stocksProductos }}</pre> -->
    </b-card>

    <!-- <producto-edit
      :producto="productoSelect"
      :is-edit-producto.sync="isEditProducto"
    /> -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormGroup, BButton,
  // BTable, BDropdown, BDropdownItem, BPagination, BMedia, BLink,
  BCardTitle, BAvatar,
  // BFormInput,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
// import { paginateArray, sortCompare } from '@/libs/filter-data'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
// import ProductoAddNew from '@/views/bimbo/components/ProductoAddNew.vue'
// import ProductoEdit from '@/views/bimbo/components/ProductoEdit.vue'
import useProductos from '../useProductos'
// import { export_json_to_excel } from '@/libs/Export2Excel'

export default {
  components: {
    // ProductoAddNew,
    // ProductoEdit,

    BCard,
    BCardTitle,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BAvatar,
    // BImg,
    // BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    // BPagination,

    // vSelect,
    BFormGroup,
    Cleave,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Export Excell
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'csv',
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },

  setup() {
    const {
      // Nuevo Producto
      isAddNewProductoSidebarActive,
      // UI
      isEditProducto,
      productoSelect,
      tableColumns,
      tableColumnsProductosInventario,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // Datos
      productos,
      productosList,
      itemsFilterProductos,
      itemsFiltereds,
      inventarios,
      inventarioProductos,
      inventarioSelected,
      stocksProductos,
      newInventoryProducts,
      newInventoryTitulo,
      newInventoryNotas,
      // methods
      fetchProductos,
      editProducto,
      addProducto,
      exportToExcel,
      fetchInventarios,
      newInventory,
      updatedStock,
    } = useProductos()

    const items = inventarioProductos.value

    return {
      isEditProducto,
      productoSelect,
      // Nuevo Producto
      isAddNewProductoSidebarActive,
      // UI
      tableColumns,
      tableColumnsProductosInventario,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // Datos
      productos,
      productosList,
      itemsFilterProductos,
      itemsFiltereds,
      inventarios,
      inventarioProductos,
      items,
      inventarioSelected,
      stocksProductos,
      newInventoryProducts,
      newInventoryTitulo,
      newInventoryNotas,
      // methods
      fetchProductos,
      editProducto,
      addProducto,
      exportToExcel,
      fetchInventarios,
      newInventory,
      updatedStock,
    }
  },

  created() {
    this.fetchProductos()
    this.fetchInventarios()
    this.newInventory()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
// @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
